const envConfigs = [
	{
		configs: {
			// ticketing_url: 'https://dev-ticket-japan-expo-com.staging.stonecode.io',
			ticketing_url: 'http://localhost:3000',
		},
		hostnames: [/.*dev-ticket-jts-admin-japan-expo-com.*/i, 'localhost'],
	},
	{
		configs: {
			ticketing_url: 'https://demo-ticket-japan-expo-com.staging.stonecode.io',
		},
		hostnames: [/.*demo-ticket-jts-admin-japan-expo-com.*/i],
	},
	{
		configs: {
			ticketing_url: 'https://preproduction-ticket-japan-expo-com.staging.stonecode.io',
		},
		hostnames: [/.*preproduction-ticket-jts-admin-japan-expo-com.*/i],
	},
	{
		configs: {
			ticketing_url: 'https://ticket.japan-expo.com',
		},
		hostnames: [/.*ticket-jts-admin\.japan-expo\.com.*/i],
	},
];

export default () => {
	const search = location.hostname;

	const config = envConfigs.find(config => {
		return config.hostnames.find(hostname => {
			if (typeof hostname === 'string') {
				return hostname === search;
			}

			return hostname.test(search);
		});
	});

	if (!config) {
		throw `Config not found for hostname ${search}`;
	}

	return config.configs;
};